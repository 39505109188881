const initState = {
  _items: [
    [0, "01/03/2021", "08:50:21", "18:50:21"],
    [1, "02/03/2021", "08:50:21", "18:50:21"],
    [2, "03/03/2021", "08:50:21", "16:00:21"],
    [0, "04/03/2021", "08:50:21", "10:50:21"],
    [2, "05/03/2021", "0", "0"],
    [0, "29/03/2021", "08:50:21", "18:50:21"],
    [0, "07/03/2021", "08:50:21", "18:50:21"],
    [0, "05/03/2021", "08:50:21", "18:50:21"],
    [0, "05/03/2021", "08:50:21", "18:50:21"],
  ],
};

export default function getTOW(state = initState, action) {
  switch (action.type) {
    case "GET_DATA_TABLE_OF_WORK":
      return { ...state, _items: action.payload };
    default:
      return { ...state };
  }
}
